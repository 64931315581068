import { AiFillHome, AiFillWechat } from 'react-icons/ai'
import { TbSocial } from "react-icons/tb";
import { RiAdminFill, RiPercentLine } from 'react-icons/ri'
import { FaUserFriends } from 'react-icons/fa'
import { BsBookFill, BsCalendar3WeekFill, BsFillPenFill } from 'react-icons/bs'
import { DeleteStudent } from './DataRequest';
import { LoadStudents } from './DataLoader';
import { MdPlayLesson } from 'react-icons/md'
import { IoIosPaper } from 'react-icons/io'
import { admins, lessons } from '../data/DataLoader'



export const gridOrderImage = (props) => (
    <img
      className="rounded-xl h-20"
      src={props.image}
      alt="order-item"/>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md">
    {props.Status}
  </button>
);

export const gridStudentAvialble = (props) => (
  <button
    type="button"
    style={{ background: `${props.available==0?'#59CE8F':'#3B9AE1'}`}}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md">
    {`${props.available==0?'Availble':'Active'}`}
  </button>
);

export const gridStudentRemove = (props) => (<ul>
  <p>{props.id}</p>
  <button
    type="button"
    style={{ background: 'white', border: '1px solid #C21010', color: '#C21010'}}
    className="py-1 px-2 capitalize rounded-lg text-md"
    onClick={() => {DeleteStudent(props.id, LoadStudents)}}>
    Delete
  </button>
</ul>);

export const lessonsGrid = [
  //{ type: 'checkbox', width: '50' },
  {
    field: 'id',
    headerText: 'ID',
    width: 'auto',
    textAlign: 'Left',
  },
  {
    field: 'image',
    template: gridOrderImage,
    width: 'auto',
    textAlign: 'Left',
  },

  {
    field: 'name',
    headerText: 'Name',
    width: 'auto',
    editType: 'dropdownedit',
    textAlign: 'Left',
  },
  {
    field: 'description',
    headerText: 'Description',
    width: 'auto',
    editType: 'dropdownedit',
    textAlign: 'Left',
  },
];

export const adminsGrid = [
  //{ type: 'checkbox', width: '50' },
  {
    field: 'id',
    headerText: 'ID',
    width: '5%',
    allowEditing: false,
    textAlign: 'Left',
  },
  {
    field: 'name',
    headerText: 'Name',
    width: 'auto',
    textAlign: 'Left',
  },
  {
    field: 'email',
    headerText: 'Email',
    width: 'auto',
    textAlign: 'Left',
  },
  {
    field: 'password',
    headerText: 'Password',
    width: 'auto',
    textAlign: 'Left',
  }
];
export const studentsGrid = [
  //{ type: 'checkbox', width: '50' },
  {
    field: 'id',
    headerText: 'ID',
    allowEditing: false,
    width: '50',
    textAlign: 'Left',
  },
  {
    field: 'code',
    headerText: 'Code',
    allowEditing: false,
    width: '80',
    textAlign: 'Left',
  },
  {
    field: 'name',
    headerText: 'Name',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'available',
    template: gridStudentAvialble,
    allowEditing: false,
    width: '80', 
    textAlign: 'Left',
  },
  {
    field: 'pn',
    headerText: 'Phone Number',
    editType: 'numericedit',
    width: '80',
    textAlign: 'Left',
  },
  {
    field: 'ppn',
    headerText: 'Phone Number',
    editType: 'numericedit',
    width: '80',
    textAlign: 'Left',
  },
  {
    field: 'country',
    headerText: 'Country',
    width: '80',
    textAlign: 'Left',
  },
  
];

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'home',
        icon: <AiFillHome className='mt-1' />
      }
    ]
  },
  {
    title: 'Pages',
    links: [
      {
        name: 'lessons',
        icon: <BsBookFill className='mt-1' />
      },
      {
        name: 'admins',
        icon: <RiAdminFill className='mt-1' />
      },
      {
        name: 'students',
        icon: <FaUserFriends className='mt-1' />
      }
    ]
  },
  {
    title: 'Apps',
    links: [
      {
        name: 'calendar',
        icon: <BsCalendar3WeekFill className='mt-1' />
      },
      {
        name: 'community',
        icon: <TbSocial className='mt-1' />
      },
      {
        name: 'messages',
        icon: <AiFillWechat className='mt-1' />
      },
      {
        name: 'exmaker',
        icon: <BsFillPenFill className='mt-1' />
      },
    ]
  }
]

export const earningData = [
  {
    title: 'Uploaded Lessons',
    icon: <MdPlayLesson />,
    iconColor: '#EDDFB3',
    iconBg: '#293462',
    amount: 24,
    percentage: '-2%',
    pcColor: 'red-500'
  },
  {
    title: 'Uploaded Exams',
    icon: <IoIosPaper />,
    iconColor: 'white',
    iconBg: '#FF1E00',
    amount: 7,
    percentage: '+10%',
    pcColor: 'green-500'
  },
  {
    title: 'Current Admins',
    icon: <RiAdminFill />,
    iconColor: '#F7ECDE',
    iconBg: '#100F0F',
    amount: 10,
    percentage: '+5%',
    pcColor: 'green-500'
  },
  {
    title: 'Average Degree',
    icon: <RiPercentLine />,
    iconColor: '#C2DED1',
    iconBg: '#354259',
    amount: '95%',
    percentage: '+1.2%',
    pcColor: 'green-500'
  }
]

export const scheduleData = [JSON.parse(`{"Subject":"Add title","Id":1,"StartTime":"2022-08-16T07:30:00.000Z","EndTime":"2022-08-16T08:00:00.000Z","IsAllDay":false,"Guid":"fa5540a7-89e9-bc08-79cc-2c94dd58ac7c","Location":"asdasdasd","StartTimezone":"Africa/Cairo","EndTimezone":"Africa/Cairo","Description":"asdasdasdasd","RecurrenceRule":"FREQ=WEEKLY;BYDAY=TU;INTERVAL=1;UNTIL=20221015T073000Z;"}`)];